






import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { MineApi } from "@/api/mine.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({})
export default class Authorization extends Vue {
  globalState = getModule(GlobalStateStore);

  handleLoginErr(): void {
    // Toast("授权登录失败");
    this.$router.replace({ name: "Store" });
  }

  async handleLogin(): Promise<void> {
    const { uri = "" } = this.$route.params;
    const { code = "" } = this.$route.query;
    if ((!code || !uri) && !this.globalState.userToken) {
      return this.handleLoginErr();
    }
    const login = await MineApi.loginByWechatCode(String(code)).catch(
      () => null
    );
    if (!login) return this.handleLoginErr();
    this.globalState.setUserToken(login.token);
    this.globalState.setOpenId(login.openid);
    const userInfo = await this.globalState.getUserInfo();
    localStorage.removeItem("vuex");
    localStorage.removeItem("vuex-new");
    if (userInfo.user_id === 951) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new VConsole();
      } catch (e) {
        console.error(e);
      }
    }
    if (!uri) {
      await this.$router.replace({ name: "Store" });
      return;
    }
    await this.$router.replace(window.atob(uri.split("--").join("/")));
  }

  async mounted(): Promise<void> {
    const toast = Toast.loading({
      message: "授权中...",
      duration: 0,
      mask: true,
    });
    await this.handleLogin();
    toast.close();
  }
}
